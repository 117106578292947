@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@600;800&display=swap');
/* @import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;800&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;700&display=swap');


* {
  margin: 0;
}

.App {
  font-family: 'Poppins', sans-serif;
  overflow-x: hidden;
}

