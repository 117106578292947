.main-nav-container-1 {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    z-index: 2;
    position: absolute;
    width: 100%;
    top: 0;
}

.main-nav-container-2 {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    z-index: 2;
    position: relative;
    width: 100%;
    top: 0;
    background: #FFFFFF;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.08);
}

a {
    text-decoration: none;
    color: #0B0B0B; 
}
  
li {
    list-style: none;
}

.logo img {
    height: 60px;
    width: 238px;
}

.tab:hover, .tab :active {
  color: #1C78BE;
}

.active {
    color: #1C78BE !important; 
}

.navbar {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 15vh;
    /* background: #FFFFFF;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.08); */
}

.logo {
    padding-left: 6vw;
}

.nav-menu{
    width: 70vw;
    padding-left: 6vw;
    /* color: #FFFFFF; */
}

.nav-menu ul{
    display: flex;
    flex-direction: row;
    align-items: center ;
}

.nav-menu li{
    padding-left: 3vw;
    padding-right: 3vw;
    font-weight: 500;
    font-size: 16px;
    /* color: #FFFFFF; */
}

.tab-1{
    color: #FFFFFF;
}

.tab-2 {
    color: #0B0B0B;
}

.hamburger {
    display: none;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 30px;
    height: 21px;
    cursor: pointer;
}

.line {
    width: 100%;
    height: 3px;
    background-color: #1B75BC;
    transition: all 0.3s ease-in-out;
    border-radius: 150px;
}

.hide-ham {
    display: none;
}

@media (max-width: 970px){

    .navbar {
        height: 8vh;
        width: auto;
        margin-top: 1rem;
    }

    .logo img{
        height: 4%;
        width: 186px;
    }

    .nav-menu {
        margin-right: 2rem;
    }

    .nav-menu li {
        font-size: 21px;
    }
}

@media (max-width: 850px) {
    .navbar {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    .hamburger {
        display: flex;
        margin-right: 25px;
        width: 23px;
        height: 15px;
    }

    .nav-menu {
        display: none;
    }
}

@media (max-width: 500px) {
    .logo img {
        width: 156px;
    }
}