.main-modal-container {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    background-color: #FFFF;
    overflow: hidden;
}

.mob-nav-menu {
    display: flex;
    width: 100%;
    top: 5rem;
    position: relative;
    gap: 2rem;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-top: 5rem;
}

.mob-nav-menu p {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #0B0B0B;
}

.nav-socials {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 2rem;
    margin-bottom: 4rem;
}

.hide {
    display: none;
}