.upperbg {
  display: flex;
  flex-direction: row;
  background-repeat: no-repeat;
  background-position: 50rem;
}

.headtext {
  flex: 0.5;
  padding: 10rem 8rem;
  font-family: "Lato";
  font-style: normal;
  font-weight: 900;
  color: white;
}

.mobImgBg {
  flex: 0.5;
  position: relative;
  background-position: 7rem 10rem;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: row;
  /* background-position:40rem; */
  padding-top: 10rem;
}

.image1 {
  position: relative;
  top:-70px;
  /* padding-right: 80rem; */
  background-repeat: no-repeat;
  /* padding-bottom: 10rem; */

}

.image1-mob {
  display: none;
}

.section1-premium {
  display: flex;
  flex-direction: row;
}

.sec1text {
  flex: 0.5;
}

.sec1img {
  flex: 0.5;
  display: flex;
  flex-direction: row;
  background-repeat: no-repeat;
  /* padding-top: 2rem; */
}

.sec1text {
  padding-left: 8rem;
  padding-top: 15rem;
  font-family: "Lato";
  font-style: normal;
  margin-right: 8rem;
  font-size: 2.5rem;
}

.sec2text {
  padding-left: 0.1rem;
  padding-top: 12rem;
  font-family: "Lato";
  font-weight: 700;
  /* font-style: normal; */
  /* margin-right: rem; */ 
  padding-right: 2rem;
}

.sec2img {
  flex: 0.5;
  display: flex;
  flex-direction: row;
  background-repeat: no-repeat;
  padding: 4rem 6rem 4rem 8rem;
}

.phonebg {
  background-repeat: no-repeat;
}

.mobImgBg-mob {
  display: none;
}

.section1-mob {
  display: none !important;
}

.section2-mob {
  display: none !important;
}

.premium-hero-heading {
  display: none !important;
}

.premium-hero-heading-desk {
  display: flex;
}

.section2 {
  display: flex;
}


/* media query */

@media(max-width:800px) {

  .section1-premium {
    display: none !important;
  }

  .section2 {
    display:none
  }

  .sec2img {
    padding: 1rem;
  }

  .section1-mob {
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px 20px !important;
  }

  .section2-mob {
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 1rem;
  }
  
  .conce-img-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .reconsider-img {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .image1 {
    display: none !important;
  }

  .mobImgBg {
    display: none !important;
  }

  .mobImg-mobile {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem 0rem;
  }

  .image1-mob {
    display: flex !important;
  }

  .upperbg {

    flex-direction: column;
    background-repeat: no-repeat;
    /* background-position: 50rem; */
  }

  .headtext {
    text-align: center;
    width: 100%;
    padding: 0rem 0rem;
    padding-top: 15px;
  }

  .premium-hero-heading {
    display: flex !important;
    width: 100%;
    font-size:36px; 
    margin-bottom: 1rem;
    margin-top: 3rem;
  }

  .premium-hero-heading-desk {
    display: none;
  }

  .sec1text {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: "Lato";
    font-style: normal;
    font-size: 1rem;
    padding-top: 2rem ;
    padding-bottom: 2rem ;

  }

  .sec2text {
    padding-left: 0px;
    padding-top: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-right: 0px;
    padding: 0rem 1rem;
  }

}