.container-TC{
    display: flex;
    justify-content: space-between;
    /* width: 80%; */
    margin: auto;
    align-items: center;
}

.container-main-TC{
    padding: 50px 80px 50px 80px;
}


.heading-TC{
    font-size:40px;
    font-weight: 900;
}

.sub-heading-TC{
    font-size: 17px;
}

@media (max-width: 900px) {
    .container-TC{
        flex-direction: column;
    }

    .heading-TC{
        font-size:30px;
        font-weight: 800;
    }
    .sub-heading-TC{
        font-size: 13px;
    }

    
.container-main-TC{
    padding: 20px;
}

}