.video-section {
    height: auto
}

.video-section-container {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 50px;
    padding-right: 75px;
    padding-left: 75px;
    position: relative;
}

.video-section::-webkit-media-controls-start-playback-button {
    display: none !important;
}

.anc {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 200px;
    height: 200px;
    background: url(../../../src/assets/playicon.svg) no-repeat center center;
    z-index: 100;
    cursor: pointer;
}


@media screen and (max-width: 860px) {
    .video-section {
        height: 250px;
    }

    .video-section-container {
        display: flex;
        justify-content: center;
        width: 100%;
        padding: 25px;
    }
}