.main-contactUs-container {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 250px;
    margin-top: 100px;
}

.headings {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 70px 0px;
    margin-top: 75px;
}

.main-heading {
    color: #0B0B0B;
    font-size: 2rem;
    font-weight: 700;
    font-family: 'Lato';
    margin-bottom: 20px;
}

.sub-heading {
    color: rgba(25, 24, 37, 0.50);
    text-align: center;
    font-size: 0.9rem;
    font-family: Lato;
    font-weight: 700;
}

.main-content {
    width: 80%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 5rem;
    border-radius: 15px 0px 0px 15px;
    background: var(--linear-white, #FFF);
    box-shadow: 0px 14px 24px 0px #F0F2F5;
    margin-bottom: 50px;
}

.contact-us-img {
    width: 33%;
    width: 33%;
    height: 100%;
    object-fit: cover;
}

.mob-contact-us-img {
    display: none;
}

.right-section {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    height: 100%;
    width: 90%;
    padding-bottom: 50px;
    padding-top: 25px;
}

.form-heading {

}

.say-hi {
    color: #0B0B0B;
    font-size: 2.25rem;
    font-family: Lato;
    font-weight: 600;
    line-height: 2.9375rem;
}

.below-say-hi {
    color: #191825;
    font-size: 1rem;
    font-weight: 600;
    font-family: Lato;
    line-height: 1.8125rem;
    margin-bottom: 15px;
}

.form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 2rem;
    /* height: 100%; */
    width: 100%;
}

.label {
    color: #191825;
    font-size: 0.875rem;
    font-weight: 600;
    font-family: Lato;
    line-height: 140%;
}

::placeholder {
    color: var(--linear-dark-snow, #C7CCD0);
    font-size: 0.875rem;
    font-family: Lato;
    line-height: 140%;
}

textarea ::placeholder {
    color: var(--linear-dark-snow, #C7CCD0);
    font-size: 0.875rem;
    font-family: Lato;
    line-height: 140%;
}

input, select, textarea {
    width: 85%;
    border: none;
    outline: none;
    border-bottom: 1px solid #C7CCD0;
    margin-bottom: 0.8rem;
}

.send-us-btn {
    width: 8.5rem;
    height: 2.5rem;
    border-radius: 8px;
    background: var(--green, linear-gradient(90deg, #9DCB3B 0%, #4DAE47 44.53%, #009648 87.76%));
    box-shadow: 0px 2px 6px 0px rgba(40, 129, 235, 0.36);
    float: right;
    color: #FFFFFF;
    font-size: 0.75rem;
    font-family: Lato;
    font-weight: 700;
    line-height: 140%;
    border: none;
    cursor: pointer;
}

.success-msg-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
    background-color: #FFF;
    border-radius: 10px;
    box-shadow: 0px 2px 6px 0px rgba(28, 30, 32, 0.21);
    margin-right: 60px;
}

.success-text {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

}

@media (max-width: 500px){

    .main-contactUs-container {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        gap: 2.5rem;
        width: 100%;
        height: auto;
        background-color: white;
        margin-bottom: 10px;
    }

    .success-msg-container {
        display: none !important;
    }

    .main-heading {
        margin-top: -50px;
    }

    .headings {
        margin-top: 1rem;
        padding: 15px 0px;
    }

    .sub-heading{
        width: 55%;
    }

    .main-content {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 0px 0px 0px;
        gap: 3rem;
    }

    .contact-us-img {
        display: none;
    }

    .mob-contact-us-img {
        display: block !important;
        width: 85% !important;
    }

    .right-section {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        align-items: center;
    }

    .form {
        width: 85%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .form-heading {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    input, select, textarea {
        width: 100%;
    }

    .field {
        width: 100%;
    }

    .btn{
        width: auto !important;
    }
}


@media (max-width: 900px){

    .main-contactUs-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: auto;
        background-color: white;
        justify-content: space-evenly;
        gap: 2.5rem;
        margin-bottom: 10px;

    }

    .headings {
        margin-top: 1rem;
    }

    /* .sub-heading{
        width: 55%;
    } */

    .main-content {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 0px 0px 0px;
        gap: 3rem;
        margin-bottom: 3rem;
    }

    .contact-us-img {
        display: none;
    }

    .mob-contact-us-img {
        display: block;
        width: 80%;
    }

    .right-section {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        align-items: center;
    }

    .form {
        width: 85%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .form-heading {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    input, select, textarea {
        width: 100%;
    }

    .field {
        width: 100%;
    }

    .btn{
        width: auto !important;
    }
}

@media (max-width: 1250px) {
    .say-hi {
        font-size: 2rem;
        font-family: Lato;
        font-weight: 600;
        line-height: 2.9375rem;
    }
    
    .below-say-hi {
        font-size: 1rem;
        font-family: Lato;
        line-height: 1.8125rem;
    }

    .label {
        font-size: 0.775rem;
        font-family: Lato;
        line-height: 140%;
    }
    
    ::placeholder {
        font-size: 0.775rem;
        font-family: Lato;
        line-height: 140%;
    }
    
    textarea ::placeholder {
        font-size: 0.775rem;
        font-family: Lato;
        line-height: 140%;
    }

    .send-us-btn {
        font-size: 0.65rem;
        font-family: Lato;
        font-weight: 700;
        line-height: 140%;
    }
}