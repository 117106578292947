body {
    overflow-x: hidden;
}

.main-hero-container {
    width: 100%;
    height: 100Vh;
    z-index: 1;
    position: relative;
}

video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.videoPoster{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.mob-background-vid {
    display: none;
}

.contentContainer {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    z-index: 3;
}

.download-btn {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 2rem;
}

.download-btn a{
    display: block;
    font-size: 0px;
}

.appstore-btn {
    width: 200px !important;
    height: auto !important;
}

.playstore-btn{
    width: 200px !important;
    height: auto !important;
}

.video-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.25); /* Adjust the opacity as needed */
}

@media (max-width: 860px) {
    .background-vid {
        display: none;
    }

    .contentContainer{
        width: unset;
        height: unset;
        top:unset;
        display: flex;
    justify-content: space-between!important;
    flex-direction: column!important;
    align-items: center!important;
    position: absolute!important;
    bottom: 13.5%;
    gap: 40px;
    }

    .content{
        position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    z-index: 3;
    }

    .video-overlay {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(0, 0, 0, 0.304);
        position: absolute;
        z-index: 2;
        width: 100%;
        height: 100vh;
    }


    .mob-background-vid {
        display: block;
    }

    body {
        overflow-x: hidden;
    }

    .download-btn {
        width: 100%;
        display: flex;
    }

    .appstore-btn {
        width: 150px !important;
        height: auto !important;
    }
    
    .playstore-btn{
        width: 150px !important;
        height: auto !important;
    }
    
}
