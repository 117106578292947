.main-container-hero-section {
  width: 100%;
  /* background: #d8d8d8; */
}

.section1 {
  height: 100vh;
  display: flex;
  flex-direction: row;
}

.headingMobile{
  color: #1B75BC;
font-family: Lato;

font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: 120%; /* 19.2px */
margin:auto;
margin-bottom: 20px;
width:80%
}

.section1 img {
  margin-top: 50px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.section2-about-us {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-top: 30px;
  /* justify-content: space-around;
  gap: 3rem; */
}

.left-section {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 70px;
}

.heading {
  background-color: white;
  border-radius: 50px;
  padding: 15px;
  margin-bottom: 10px;
  background: #FFFFFF;
  box-shadow: 0px 548px 219px rgba(0, 0, 0, 0.01), 0px 308px 185px rgba(0, 0, 0, 0.04), 0px 137px 137px rgba(0, 0, 0, 0.06), 0px 34px 75px rgba(0, 0, 0, 0.07), 0px 0px 0px rgba(0, 0, 0, 0.07);
  border-radius: 100px;

}

.joompaForeverDesktop{
  color: #1B75BC;
font-family: Lato;
font-size: 20px;
font-style: normal;
font-weight: 800;
line-height: 120%; /* 24px */
letter-spacing: 0.2px;
text-transform: uppercase;
}
.heading p {
  /* font-family: 'Lato'; */
  color: #1b75bc;
  margin-left: 8px;
  margin-right: 8px;
  font-style: normal;
  font-size: 1rem;
  line-height: 120%;
}

.large-content {
  display: flex;
  flex-direction: column;
  font-style: normal;
  font-weight: 900;
  font-size: 3rem;
  line-height: 120%;
  letter-spacing: 0.01em;
  margin-top: 25px;
  margin-bottom: 25px;
}

.matching p {
  background: linear-gradient(to right, #9dcb3b, #4dae47, #009648);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.small-content {
  display: flex;
  flex-direction: column;
}

.small-content p {
  font-family: 'Lato';
  color: rgba(25, 24, 37, 0.5);
  font-style: normal;
  font-weight: 400;
  font-size: 1.3rem;
  line-height: 160%;
}

.section-2-mob-img {
  display: none;
}

.section3 {
  height: 100vh;
  padding-top: 100px;
}

.section3-mobile {
  display: none !important;
}



/* Media Query 1100px -------------------------*/

@media (max-width: 1100px) {
  

  .section-2-img {
    width: 60%;
    height: auto;
    /* position: absolute;
    right: 1.5rem; */
  }

  .heading p {
    font-size: 14px;
  }

  .large-content {
    font-size: 42px;
  }

  .small-content p {
    font-size: 19px;
    text-align: center;
  }
}


/* Media Query 850px -------------------------*/


@media (max-width: 850px) {

  .section1 {
    height: 40vh;
  }

  .imgsec3 {
    display:flex !important;
    justify-content:center !important;
    align-items:center !important;
  }
  .section2-about-us {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 2rem;
    height: 80%;
  }

  .section-2-img {
    display: none;
  }

  .section-2-mob-img {
    display: block;
    width: 65%;
  }

  .heading p {
    font-size: 19px;
  }

  .large-content {
    font-size: 3rem;
  }

  .small-content p {
    font-size: 23px;
    text-align: center;

  }

  .left-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 0px;
    margin-top: 20px;

  }

  .large-content{
    font-size: 24px !important;
    text-align: center;
  }

  .section3 {
    display: none !important;
  }

  .section3-mobile {
    display: block !important;
  }


  body {
    overflow-x: hidden;
  }

  .boxes1 {
    height:48vh;
    flex-direction: column;
    padding-right: 35px;
    padding-left: 35px;
  }
  .boxes2 {
    height:48vh;
    flex-direction: column;
    padding-right: 35px;
    padding-left: 35px;
  }

  .smileman {
    padding-left:0px !important;
    padding-right:0px !important;
  }


}

/* Media Query 500px -------------------------*/


@media (max-width: 500px) {

  .section2-about-us {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 2rem;
    height: 80%;
  }

  .boxes1 {
    flex-direction: column;
    padding-right: 35px;
    padding-left: 35px;
  }

  .boxes2 {
    flex-direction: column;
    padding-right: 35px;
    padding-left: 35px;
  }

  .smileman {
    padding-left:0px !important;
    padding-right:0px !important; 
  }

  .section3 {
    display: none !important;
  }

  .section3-mobile {
    display: block !important;
  }



  body {
    overflow-x: hidden;
  }

  .large-content{
    font-size: 24px !important;
    text-align: center;
  }

  .section-2-img {
    display: none;
  }

  .section-2-mob-img {
    display: block;
    width: 80%;
  }

  .heading p {
    font-size: 14px;
  }

  .large-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 32px;
  }

  .small-content p {
    font-size: 16px;
    font-weight: 500;
    text-align: center;

  }

  .left-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 0px;
    margin-top: 20px;
  }

  .boxtext21, .boxtext22,.boxtext23,.boxtext24{
    font-size: 27px!important;
  }
  .smbox3 p{
    margin-top: 20px;
  }
  .boxtext1,.boxtext2,.boxtext3,.boxtext4{
    font-size: 30px!important;
  }

}


.section3 {
  display: flex;
  flex-direction: row;
}

.imgsec3 {
  flex: 0.5;

}

.smileman {
  /* position: absolute; */
  width: 520.82px;
  height: 457.43px;
  padding-left: 8rem;
  padding-top: 4rem;

  /* transform: matrix(-1, 0, 0, 1, 0, 0); */
}

.joompaForever{
  color: #1B75BC;
text-align: center;
font-family: Lato;
font-size: 18px;
font-style: normal;
font-weight: 800;
line-height: 120%; /* 21.6px */
letter-spacing: 0.18px;
text-transform: uppercase;
}
.textsec3 {
  flex: 0.5;
  font-family: "Poppins";
  font-style: normal;
  /* font-weight: 900; */
  font-size: 16px;
  line-height: 180%;
  /* or 53px */

  letter-spacing: 0.01em;
}

.boxes1 {
  display: flex;
  flex-direction: row;
  padding-right: 35px;
}

.smbox1 {
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* align-items: center; */
  padding: 32px;
  gap: 16px;
  margin-right: 2rem;

  width: 15.31px;
  height: 130px;

  /* white */

  background: #ffffff;
  /* Grey Scale/Black 10 */

  border: 1px solid rgba(25, 24, 37, 0.1);
  border-radius: 32px;

  /* Inside auto layout */

  /* flex: none; */
  flex: 0.2;
  order: 0;
  flex-grow: 1;
}

.smbox2 {
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: column;
  /* align-items: center; */
  padding: 32px;
  gap: 16px;
  justify-content: center;
  align-items: center;

  /* width: 15.31px; */
  height: 130px;
  /* margin-right: 15rem; */

  /* white */

  background: #ffffff;
  /* Grey Scale/Black 10 */

  border: 1px solid rgba(25, 24, 37, 0.1);
  border-radius: 32px;

  /* Inside auto layout */

  flex: 0.2;
  order: 0;
  flex-grow: 1;
}

.boxes2 {
  display: flex;
  flex-direction: row;
  padding-right: 35px;

}

.smbox3 {
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* align-items: center; */
  padding: 32px;
  gap: 16px;
  margin-right: 2rem;

  width: 15.31px;
  height: 130px;

  /* white */

  background: #ffffff;
  /* Grey Scale/Black 10 */

  border: 1px solid rgba(25, 24, 37, 0.1);
  border-radius: 32px;

  /* Inside auto layout */

  flex: 0.2;
  order: 0;
  flex-grow: 1;
}

.smbox4 {
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* align-items: center; */
  padding: 32px;
  gap: 16px;

  /* margin-right: 15rem; */
  /* width: 15.31px; */
  height: 130px;

  /* white */

  background: #ffffff;
  /* Grey Scale/Black 10 */

  border: 1px solid rgba(25, 24, 37, 0.1);
  border-radius: 32px;

  /* Inside auto layout */

  flex: 0.2;
  order: 0;
  flex-grow: 1;
}

.boxtext1 {
  /* padding-left: 75px; */
  margin-bottom: -20px;

}

.boxtext2 {
  /* padding-left: 50px; */
  margin-bottom: -20px;

}

.boxtext3 {
  /* padding-left: 55px; */
  margin-bottom: -20px;

}

.boxtext4 {
  /* padding-left: 62px; */
  margin-bottom: -20px;

}

.boxtext21 {
  /* font-family: 'Lato'; */
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 280%;
  /* identical to box height, or 29px */
  /* padding-left: 50px; */

  color: #0B0B0B;
}

.boxtext22 {
  /* font-family: 'Lato'; */
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 280%;
  /* identical to box height, or 29px */
  /* padding-left: 50px; */

  color: #0B0B0B;
}

.boxtext23 {
  /* font-family: 'Lato'; */
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  /* line-height: 280%; */
  text-align: center;
  /* identical to box height, or 29px */
  /* padding-left: 30px; */

  color: #0B0B0B;
}

.boxtext24 {
  /* font-family: 'Lato'; */
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 280%;
  /* identical to box height, or 29px */
  /* padding-left: 65px; */

  color: #0B0B0B;
}