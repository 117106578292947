.main-box1 {
  width: 100vw;
  display: flex;
  flex-direction: row;
  height: 2rem;
  background-color: #232a34;
  height: 18rem;
}

/* .fa-phone-icon {
  transform: scaleX(-1);
} */

.box1 {
  flex: 0.35;
}

.logoimg {
  width: 15rem;
  padding: 4rem 4rem 1rem 8rem;
}

.text1 {
  color: white;
  padding-left: 8rem;
  font-size: 14px;
  font-weight: lighter;
  font-family: "Lato";
}

.btnSection {
  display: flex;
  flex-direction: row;
}

.btn1 {
  width: 136px;
  height: 41px;
  padding: 2rem 2rem 0 8rem;
}

.btn2 {
  width: 136px;
  height: 41px;
  padding: 2rem 2rem 0 0;
}

.box2 {
  color: white;
  flex: 0.2;
}

.b21 {
  padding: 4rem 4rem 2rem 4rem;
}

.b22 {
  padding: 0 4rem;
  font-size: 16px;
  font-family: "Lato";
  font-weight: 700px;
  cursor: pointer;
}

.b23 {
  padding: 2rem 4rem;
  font-size: 16px;
  font-weight: lighter;
  font-family: "Lato";
  cursor: pointer;

}

.b24 {
  padding: 0 4rem;
  font-size: 16px;
  font-weight: lighter;
  font-family: "Lato";
  cursor: pointer;

}

.box3 {
  color: white;
  flex: 0.25;
}

.b31 {
  padding: 4rem 4rem 2rem 4rem;
}

.icons1 {
  display: flex;
  flex-direction: row;
}

.b32 {
  padding: 0 1rem;
  font-size: 16px;
  font-weight: lighter;
  font-family: "Lato";
}

.b33 {
  padding: 2rem 1rem;
  font-size: 16px;
  font-weight: lighter;
  font-family: "Lato";
}

.b34 {
  padding: 0 1rem;
  font-size: 16px;
  font-weight: lighter;
  font-family: "Lato";
}

.box4 {
  color: white;
  flex: 0.25;
}

.b41 {
  padding: 4rem 4rem 2rem 4rem;
}

.icons {
  align-content: space-between;
}

.main-box2 {
  display: flex;
  width: 100vw;
  justify-content: center;
  align-items: center;
  padding: 20px 0px;
  color: white;
  background-color: black;
  /* height: 4rem; */
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  align-items: center;
  /* padding: 0 40rem; */
}

.social-icons-container-mob {
  display: none !important;
}

.box2-mob {
  display: none !important;
}

.box3-mob {
  display: none !important;
}

.footer-mobile {
  display: none !important;
}


@media (max-width:500px) {
  .main-box2{
    font-size: 11px;
    text-align: center;
  }
}
@media (max-width: 860px) {
  .main-box1 {
    display: flex;
    flex-direction: row;
    height: auto;
    flex-wrap: wrap;
    justify-content: center;
  }

  .b21 {
    padding-left: 2rem;
  }

  .b22 {
    padding-left: 2rem;
  }

  .b23 {
    padding-left: 2rem;
  }


  .b24 {
    padding-left: 2rem;
  }


  .footer-mobile {
    display: flex !important;
    justify-content: space-evenly !important;
    align-items: center !important;
    padding: 10px 15px !important;
    width: 100% !important;
  }

  .footer-header {
    color: white !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    display: flex !important;
  }

  .box1 {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 50px;
  }

  .btnSection {
    margin-top: 50px;
  }

  .box2 {
    display: none !important;
  }

  .box2-mob {
    width: 50%;
    display: flex !important;
    flex-direction: column !important;
    color: white !important;
  }

  .box3 {
    /* width: 50%; */
    display: none !important;
  }

  .box4 {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    align-items: center;
  }

  .logoimg {
    padding: 0px;
  }

  .text1 {
    padding: 0px;
  }

  .btn1 {
    padding: 0px;
    margin-right: 10px;
  }

  .btn2 {
    padding: 0px;
  }

  .social-icons-container-mob {
    display: flex !important;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    width: 15%;
    margin-bottom: 2rem;
    margin-top: 30px;
  }

  .social-icons-container {
    display: none !important;
  }

  .b32 {
    padding: 0 0;
    font-size: 14px;

  }
  
  .b33 {
    padding: 2rem 0;
    font-size: 14px;

  }
  
  .b34 {
    padding: 0 2px;
    font-size: 14px;

  } 

  .b41 {
    display: none !important;
  }

  .box3-mob {
    display: flex !important;
    flex-direction: column !important;

    width: 50% !important;
    color: white !important;
    /* justify-content: center;
    align-items: center;
    margin-top: 50px; */
  }

  .b31 {
    padding: 4rem 4rem 2rem 0;
  } 
  

  /* .box3 {
    display: none !important;
  } */
}

.social-icons-container-mob a{
   display: block;
    font-size: 0px;
}

.social-icons-container-mob svg{
  font-size: 17px;
}

a {
  -webkit-tap-highlight-color: transparent !important;
}
